import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c(VDialog, {
    attrs: {
      "scrollable": "",
      "max-width": "1000px",
      "persistent": ""
    },
    model: {
      value: _vm.filtersActive,
      callback: function ($$v) {
        _vm.filtersActive = $$v;
      },
      expression: "filtersActive"
    }
  }, [_c(VCard, [_c(VCardTitle, {
    staticClass: "mx-0 px-0 my-0 py-0"
  }, [_c(VToolbar, {
    staticClass: "mx-0 px-0 my-0 py-0",
    attrs: {
      "color": "primary",
      "fixed": "",
      "top": "",
      "dark": ""
    }
  }, [_c(VIcon, {
    on: {
      "click": function ($event) {
        _vm.toggleFilter(), _vm.isFilterSet();
      }
    }
  }, [_vm._v("mdi-close")]), _c(VToolbarTitle, {
    staticClass: "flex text-center"
  }, [_vm._v("Artikelsuche Filtern "), _c('NameSearchHelpDialog')], 1)], 1)], 1), _c(VCardText, [_c(VContainer, {
    staticClass: "mt-15 mb-4",
    attrs: {
      "justify": "center"
    }
  }, [_c(VRow, [_c(VCol, [_c(VTextField, {
    staticClass: "mx-sm-1",
    attrs: {
      "outlined": "",
      "label": "Artikelnummer",
      "clearable": ""
    },
    model: {
      value: _vm.articlenumber,
      callback: function ($$v) {
        _vm.articlenumber = $$v;
      },
      expression: "articlenumber"
    }
  })], 1), _c(VCol, [_c(VTextField, {
    staticClass: "mx-sm-1",
    attrs: {
      "outlined": "",
      "label": "Artikelnummer 2",
      "clearable": ""
    },
    model: {
      value: _vm.articlenumber_2,
      callback: function ($$v) {
        _vm.articlenumber_2 = $$v;
      },
      expression: "articlenumber_2"
    }
  })], 1)], 1), _c(VRow, [_c(VCol, [_c(VTextField, {
    staticClass: "mx-sm-1",
    attrs: {
      "outlined": "",
      "label": "Artikelbezeichnung",
      "clearable": ""
    },
    model: {
      value: _vm.articledescription,
      callback: function ($$v) {
        _vm.articledescription = $$v;
      },
      expression: "articledescription"
    }
  })], 1)], 1), _c(VRow, [_c(VCol, [_c(VAutocomplete, {
    staticClass: "mx-sm-1",
    attrs: {
      "outlined": "",
      "items": _vm.allarticletypeitems,
      "item-value": "id",
      "return-object": "",
      "item-text": item => item.description,
      "label": "Artikeltype",
      "clearable": ""
    },
    model: {
      value: _vm.articletype,
      callback: function ($$v) {
        _vm.articletype = $$v;
      },
      expression: "articletype"
    }
  })], 1)], 1), _c(VRow, [_c(VCol, [_c(VAutocomplete, {
    staticClass: "mx-sm-1",
    attrs: {
      "outlined": "",
      "label": "Artikelkategorie",
      "clearable": "",
      "items": _vm.allarticlecategoryitems,
      "item-text": "description",
      "item-value": "id",
      "disabled": _vm.articletype == null ? true : false
    },
    model: {
      value: _vm.articlecategory,
      callback: function ($$v) {
        _vm.articlecategory = $$v;
      },
      expression: "articlecategory"
    }
  })], 1), _c(VCol, [_c(VAutocomplete, {
    staticClass: "mx-sm-1",
    attrs: {
      "outlined": "",
      "items": _vm.allarticlegroupitems,
      "item-value": "id",
      "return-object": "",
      "item-text": "description",
      "label": "Artikelgruppe",
      "clearable": "",
      "disabled": _vm.articletype == null ? true : false
    },
    model: {
      value: _vm.articlegroup,
      callback: function ($$v) {
        _vm.articlegroup = $$v;
      },
      expression: "articlegroup"
    }
  })], 1)], 1)], 1)], 1), _c(VCardActions, [_c(VSpacer), _c(VBtn, {
    attrs: {
      "color": "error"
    },
    on: {
      "click": function ($event) {
        return _vm.clearFilter();
      }
    }
  }, [_vm._v("Filter aufheben")]), _c(VBtn, {
    staticClass: "primary",
    on: {
      "click": function ($event) {
        _vm.searchTrigger(), _vm.toggleFilter(), _vm.isFilterSet();
      }
    }
  }, [_vm._v(" Suchen ")])], 1)], 1)], 1), _c(VRow, {
    staticClass: "mt-1",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c(VCol, {
    attrs: {
      "cols": "12",
      "sm": "9"
    }
  }, [_c(VTextField, {
    staticClass: "px-8 mt-8",
    attrs: {
      "append-icon": "mdi-magnify",
      "label": "Textsuche (mind. 3 Zeichen)",
      "rules": [_vm.twoCharactersValidation],
      "clearable": "",
      "underlined": ""
    },
    on: {
      "input": _vm.searchTrigger
    },
    model: {
      value: _vm.fullTextSearchInput,
      callback: function ($$v) {
        _vm.fullTextSearchInput = $$v;
      },
      expression: "fullTextSearchInput"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "sm": "1"
    }
  }, [!_vm.filterSet && !_vm.isMobile() ? _c(VBtn, {
    staticClass: "seondary",
    attrs: {
      "block": ""
    },
    on: {
      "click": _vm.toggleFilter
    }
  }, [_c(VIcon, {
    staticClass: "mr-2"
  }, [_vm._v(" mdi-filter-outline ")]), _vm._v(" Filter ")], 1) : !_vm.filterSet && _vm.isMobile() ? _c(VBtn, {
    staticClass: "seondary",
    attrs: {
      "block": ""
    },
    on: {
      "click": _vm.toggleFilter
    }
  }, [_c(VIcon, [_vm._v(" mdi-filter-outline ")])], 1) : _vm.filterSet && _vm.isMobile() ? _c(VBtn, {
    staticClass: "primary",
    attrs: {
      "block": ""
    },
    on: {
      "click": _vm.toggleFilter
    }
  }, [_c(VIcon, [_vm._v(" mdi-filter ")])], 1) : _c(VBtn, {
    staticClass: "primary",
    attrs: {
      "block": ""
    },
    on: {
      "click": _vm.toggleFilter
    }
  }, [_c(VIcon, {
    staticClass: "mr-2"
  }, [_vm._v(" mdi-filter ")]), _vm._v(" Filter ")], 1)], 1)], 1), _c(VDivider, {
    staticClass: "mt-6"
  }), _c('div', {
    ref: "resultsTable"
  }, [_vm.results.length > 0 ? _c(VDataTable, {
    staticClass: "mt-6 datatable-row-pointer",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.results,
      "items-per-page": 10,
      "loading": _vm.loading,
      "loading-text": "Artikel werden geladen ..."
    },
    on: {
      "click:row": _vm.openArticleDataTable
    },
    scopedSlots: _vm._u([{
      key: "item.name",
      fn: function ({
        item
      }) {
        return [_c(VTooltip, {
          attrs: {
            "left": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function ({
              on
            }) {
              return [_c(VAvatar, _vm._g({
                staticClass: "mr-4 elevation-2",
                attrs: {
                  "size": "34",
                  "color": item.active ? 'white' : 'red'
                }
              }, on), [_c(VIcon, {
                attrs: {
                  "color": item.active ? 'black' : 'white'
                }
              }, [_vm._v(_vm._s(_vm.articleType(item).icon))])], 1)];
            }
          }], null, true)
        }, [_c('span', [_vm._v(_vm._s(_vm.articleType(item).name))]), _c('br'), !item.active ? _c('span', [_vm._v("inaktiv")]) : _vm._e()]), _vm._v(" " + _vm._s(item.name) + " ")];
      }
    }, {
      key: "no-data",
      fn: function () {
        return [_vm._v(" Keine Daten gefunden. ")];
      },
      proxy: true
    }, {
      key: "footer.prepend",
      fn: function () {
        return [_c(VBtn, {
          staticClass: "ml-2",
          attrs: {
            "small": "",
            "depressed": "",
            "color": "white"
          },
          on: {
            "click": function ($event) {
              return _vm.scrollToTop();
            }
          }
        }, [_vm._v(" Nach oben "), _c(VIcon, [_vm._v("mdi-arrow-up-thin")])], 1)];
      },
      proxy: true
    }], null, false, 179801304)
  }) : _vm._e()], 1), _c(VLayout, {
    attrs: {
      "justify-center": "",
      "align-center": ""
    }
  }, [_c(VFlex, {
    attrs: {
      "shrink": ""
    }
  }, [_vm.results.length == 0 && _vm.loading == false ? _c(VImg, {
    staticClass: "mt-16",
    attrs: {
      "src": require("@/assets/svg/undraw_empty.svg"),
      "max-width": "350px"
    }
  }) : _vm._e()], 1)], 1), _c('br'), _vm.results.length == 0 && _vm.fullTextSearchInput.length <= 2 && _vm.loading == false ? _c('h2', {
    attrs: {
      "align": "center"
    }
  }, [_vm._v(" Es konnten keine Artikel gefunden werden. ")]) : _vm._e(), _vm.results.length == 0 && _vm.fullTextSearchInput.length > 2 && _vm.loading == false ? _c('h2', {
    attrs: {
      "align": "center"
    }
  }, [_vm._v(" Zu dieser Suche konnten keine Artikel gefunden werden. ")]) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };